import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as api from '../../utils/api/apiClient'
import Loading from '../../components/Loading'
import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'
import { Button, Chip, Stack, Switch } from '@mui/material'
import { toast } from 'react-toastify'
import { MdEdit, MdDelete } from 'react-icons/md'
import { Helmet } from 'react-helmet'
import AddTagToProductPopup from '../../components/ProductComponent/AddTagToProductPopup'
import CreateVariantToProductPopup from '../../components/ProductComponent/CreateVariantToProductPopup'
import ProductEdit from '../../components/ProductComponent/ProductEdit'
import AddAttributeValueToProductPopup from '../../components/ProductComponent/AddAttributeValueToProductPopup'
import GalleryImage from '../../components/FullScreenSlider'
import { normalPrice } from '../../utils/PriceCalculate'

function ProductDetail() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const { id } = useParams()
  const [refresh, setRefresh] = useState(false)
  const [published, setPublished] = useState(true)
  const [addTagsToIsOpen, setAddTagsToIsOpen] = useState(false)
  const [createVariantToIsOpen, setCreateVariantToIsOpen] = useState(false)
  const [addAttributeValueToIsOpen, setAddAttributeValueToIsOpen] =
    useState(false)
  const [editVariantToIsOpen, setEditVariantToIsOpen] = useState({
    isOpen: false,
    data: {},
  })
  const [editProductToIsOpen, setEditProductToIsOpen] = useState(false)
  const navigate = useNavigate()

  // useEffect(() => {
  //   const getOneProduct = async () => {
  //     setLoading(true)
  //     try {
  //       const res = await api.getProductById(id)
  //       if (res) {
  //         const obj = { ...res.data }
  //         console.log(obj)
  //         console.log(res.data.thumbnail)
  //         obj.image_urls.push(res.data.thumbnail)
  //         setData(obj)
  //         setLoading(false)
  //         setPublished(res.data.is_published)
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   }
  //   getOneProduct()
  // }, [id, refresh])

  useEffect(() => {
    const getOneProduct = async () => {
      setLoading(true)
      try {
        const res = await api.getProductById(id)
        if (res && res.data) {
          const obj = { ...res.data }

          // Ensure image_urls exists and is an array
          obj.image_urls = obj.image_urls || []

          // Only push thumbnail if it exists
          if (res.data.thumbnail) {
            obj.image_urls.push(res.data.thumbnail)
          }

          setData(obj)
          setLoading(false)
          setPublished(res.data.is_published)
        } else {
          // Handle case where no data is returned
          setLoading(false)
          console.log('No product data found')
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    getOneProduct()
  }, [id, refresh])

  const changeToPublic = async (isPublic) => {
    setLoading(true)
    try {
      const res = await api.updateProduct(data.id, {
        is_published: isPublic,
      })

      if (res) {
        setRefresh(!refresh)
        setLoading(false)
      }
      console.log(res.data)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setRefresh(!refresh)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const deleteVariant = async (v_id) => {
    setLoading(true)
    try {
      const res = await api.removeVariant(v_id)
      if (res) {
        setRefresh(!refresh)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const deleteProduct = async () => {
    try {
      const res = await api.deleteProduct(id)
      if (res) {
        navigate('/products')
        setRefresh(!refresh)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const removeTagFromProduct = async (tag_id) => {
    setLoading(true)
    try {
      const res = await api.deleteTagFromVariant(tag_id)
      if (res) {
        setLoading(false)
        setRefresh(!refresh)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const deleteAttributeValue = async (attribute_id) => {
    setLoading(true)
    try {
      const res = await api.removeAttributeValues(id, attribute_id)
      console.log(res)
      if (res) {
        setRefresh(!refresh)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Product Detail | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      {/* Add Tag To Product */}
      {addTagsToIsOpen && (
        <AddTagToProductPopup
          isOpen={addTagsToIsOpen}
          productId={id}
          handleClose={() => {
            setAddTagsToIsOpen(false)
            setRefresh(!refresh)
          }}
        />
      )}
      {/* Create Variant To Product */}
      {createVariantToIsOpen && (
        <CreateVariantToProductPopup
          isOpen={createVariantToIsOpen}
          categoryId={data?.category_id}
          productId={id}
          handleClose={() => {
            setCreateVariantToIsOpen(false)
            setRefresh(!refresh)
          }}
        />
      )}
      {/* Add Attribute Value To Product */}
      {addAttributeValueToIsOpen && (
        <AddAttributeValueToProductPopup
          isOpen={addAttributeValueToIsOpen}
          categoryId={data?.category_id}
          productId={id}
          handleClose={() => {
            setAddAttributeValueToIsOpen(false)
            setRefresh(!refresh)
          }}
        />
      )}
      {/* Edit Variant To Product */}
      {editVariantToIsOpen && (
        <CreateVariantToProductPopup
          isOpen={editVariantToIsOpen.isOpen}
          WhatDoYouWant={'edit'}
          data={editVariantToIsOpen.data}
          productId={id}
          categoryId={data?.category_id}
          handleClose={() => {
            setEditVariantToIsOpen({ isOpen: false, data: {} })
            setRefresh(!refresh)
          }}
        />
      )}

      {/* Edit Product To Product */}
      {editProductToIsOpen && (
        <ProductEdit
          isOpen={editProductToIsOpen}
          handleClose={() => {
            setEditProductToIsOpen(false)
            setRefresh(!refresh)
          }}
          productId={id}
          data={data}
        />
      )}
      <div className='md:flex w-full pt-2 pr-2 pl-2 lg:pl-0 pb-5'>
        <div className='md:w-[45%] w-full'>
          <div>
            {data?.image_urls?.length > 0 && (
              <GalleryImage
                arrayOfImage={data?.image_urls}
                videoUrl={data?.video_url}
              />
            )}
          </div>
        </div>
        {/* ********Product-Details******** */}
        <div className='w-full md:w-[55%] bg-white rounded-lg shadow-lg p-6'>
          {/* Header Section */}
          <div className='relative border-b pb-4 mb-6'>
            <div className='flex justify-between'>
              <h2 className='text-2xl font-bold text-gray-800'>{data?.name}</h2>
              <div className='flex h-10  gap-3'>
                <button className='p-2 hover:bg-blue/40 rounded-full transition-colors'>
                  <MdEdit
                    onClick={() => setEditProductToIsOpen(true)}
                    className='text-blue text-xl'
                  />
                </button>
                <button className='p-2 hover:bg-red-50 rounded-full transition-colors'>
                  <MdDelete
                    onClick={deleteProduct}
                    className='text-red-600 text-xl'
                  />
                </button>
              </div>
            </div>

            <div className='mt-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='bg-gray-50 p-3 rounded-lg'>
                <p className='font-medium text-gray-700'>Note</p>
                <p className='text-gray-600 line-clamp-2'>{data?.note}</p>
              </div>
              <div className='bg-gray-50 p-3 rounded-lg'>
                <p className='font-medium text-gray-700'>Meta Keywords</p>
                <p className='text-gray-600 line-clamp-2'>
                  {data?.meta_keywords}
                </p>
              </div>
            </div>

            <div className='mt-4 flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <span className='font-medium text-gray-700'>Category:</span>
                <span className='bg-blue/20 text-blue px-3 py-1 rounded-full text-sm'>
                  {data?.category_name}
                </span>
              </div>

              <div className='flex items-center gap-2'>
                <span className='font-medium text-gray-700'>Published</span>
                <Switch
                  checked={published}
                  onChange={() => {
                    changeToPublic(!published)
                    setPublished(!published)
                  }}
                  className='ml-2'
                />
              </div>
            </div>
          </div>

          {/* Attributes Section */}
          <div className='mb-8'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-2xl font-semibold text-gray-800'>
                Attributes
              </h3>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setAddAttributeValueToIsOpen(true)}
                className='bg-blue/60 hover:bg-blue'
              >
                Add Attribute
              </Button>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              {data?.attributes?.map((attr, i) => (
                <div
                  key={i}
                  className='group relative bg-white border rounded-lg p-4 hover:border-blue-500 transition-all'
                >
                  <div className='absolute inset-0 bg-black opacity-0 group-hover:opacity-50 rounded-lg transition-opacity' />
                  <button
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 z-10'
                    onClick={() =>
                      deleteAttributeValue(attr.attribute_value_id)
                    }
                  >
                    <MdDelete className='text-white text-2xl' />
                  </button>
                  <p className='font-medium'>
                    {attr.name}:{' '}
                    <span className='text-gray-600'>{attr.value}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Variants Section */}
          <div className='mb-8'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-2xl font-semibold text-gray-800'>Variants</h3>
              <div className='flex gap-5'>
                <div className='flex items-center gap-2'>
                  <span className='font-medium text-gray-700'>
                    Estimated Days:
                  </span>
                  <span className='bg-blue/20 text-blue  px-3 py-1 rounded-full text-sm'>
                    {data?.estimated_delivery_days}
                  </span>
                </div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setCreateVariantToIsOpen(true)}
                  className='bg-blue/60 hover:bg-blue'
                >
                  Create Variant
                </Button>
              </div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
              {data?.variants?.map((variant, i) => (
                <div
                  key={i}
                  className='bg-white border rounded-lg p-4 hover:shadow-md transition-shadow relative'
                >
                  <div className='absolute top-2 right-2 flex gap-2'>
                    <button
                      className='p-1 hover:bg-blue/50 rounded-full transition-colors'
                      onClick={() =>
                        setEditVariantToIsOpen({ isOpen: true, data: variant })
                      }
                    >
                      <MdEdit className='text-blue text-xl' />
                    </button>
                    <button
                      className='p-1 hover:bg-error/45 rounded-full transition-colors'
                      onClick={() => deleteVariant(variant.variant_id)}
                    >
                      <MdDelete className='text-red-600 text-xl' />
                    </button>
                  </div>
                  <div className='space-y-2'>
                    <p className='font-medium text-lg'>{variant.name}</p>
                    <div className='grid grid-cols-2 gap-2 text-sm'>
                      <p>
                        <span className='font-medium'>Quantity:</span>{' '}
                        {variant.quantity}
                      </p>
                      <p>
                        <span className='font-medium'>Price:</span>{' '}
                        {normalPrice(variant.price)}
                      </p>
                      <p>
                        <span className='font-medium'>Discount:</span>{' '}
                        {variant.discount}%
                      </p>
                      <p>
                        <span className='font-medium'>Code:</span>{' '}
                        {variant.product_code}
                      </p>
                    </div>
                    <div
                      className='text-gray-600 text-sm mt-2 line-clamp-2'
                      dangerouslySetInnerHTML={{
                        __html: variant.variant_description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Product Information */}
          <div className='mb-8'>
            <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
              Product Information
            </h3>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              {[
                {
                  label: 'Uploaded',
                  value: formatDateTimeWithAMAndPM(data?.uploaded_at),
                },
                {
                  label: 'Created',
                  value: formatDateTimeWithAMAndPM(data?.created_at),
                },
                {
                  label: 'Updated',
                  value: formatDateTimeWithAMAndPM(data?.updated_at),
                },
              ].map((info, i) => (
                <div key={i} className='bg-gray-50 p-3 rounded-lg'>
                  <p className='font-medium text-gray-700'>{info.label}</p>
                  <p className='text-gray-600 text-sm'>{info.value}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Tags Section */}
          <div className='mb-8'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-2xl font-semibold text-gray-800'>Tags</h3>
              <Button
                variant='outlined'
                onClick={() => setAddTagsToIsOpen(true)}
                className='border-blue text-blue hover:bg-blue/70'
              >
                Add Tags
              </Button>
            </div>
            <div className='flex flex-wrap gap-2'>
              {data?.tags?.map((tag, i) => (
                <Chip
                  key={i}
                  label={tag.name}
                  onDelete={() => removeTagFromProduct(tag?.product_tag)}
                  className='bg-gray-100  hover:bg-gray-200'
                />
              ))}
            </div>
          </div>

          {/* Description Section */}
          <div>
            <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
              Description
            </h3>
            <div
              className='prose max-w-none text-gray-600'
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetail
