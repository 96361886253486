import React, { useEffect, useState } from 'react'
import Description from './Description'
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import * as api from '../../utils/api/apiClient'
import Loading from '../Loading'
import { toast } from 'react-toastify'
import CreateCategory from '../../components/ReUseFunctionComponent/CreateCategory'

function ProductForm({ productId, data, WhatDoYouWant, handleClose }) {
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [allCategories, setAllCategories] = useState([])
  const [productFormData, setProductFormData] = useState({
    name: '',
    description: '',
    category_id: '',
    meta_description: '',
    meta_keywords: '',
    video_url: '',
    note: '',
    estimated_delivery_days: '',
    thumbnail: {},
  })

  // edit data will be here (set to state)
  useEffect(() => {
    if (data) {
      setProductFormData({
        name: data.name,
        description: data.description,
        category_id: data.category_id,
        meta_description: data.meta_description,
        meta_keywords: data.meta_keywords,
        estimated_delivery_days: data.estimated_delivery_days,
        video_url: data.video_url,
        note: data.note,
        // thumbnail: data.thumbnail,
      })
    }
  }, [data])

  // All Categories
  useEffect(() => {
    const getCategories = async () => {
      setLoading(true)
      try {
        const categories = await api.getAllCategories()
        setAllCategories(categories.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    getCategories()
  }, [refresh])

  const handleChange = (event) => {
    if (event.target.name === 'thumbnail') {
      setProductFormData({
        ...productFormData,
        [event.target.name]: event.target.files[0],
      })
    } else {
      setProductFormData({
        ...productFormData,
        [event.target.name]: event.target.value,
      })
    }
  }

  // Creating a Product
  const createProduct = async (event) => {
    setLoading(true)
    event.preventDefault()
    const data = new FormData()
    data.append('name', productFormData.name)
    data.append('description', productFormData.description)
    data.append('category_id', productFormData.category_id)
    data.append('meta_description', productFormData.meta_description)
    data.append('meta_keywords', productFormData.meta_keywords)
    data.append('video_url', productFormData.video_url)
    data.append(
      'estimated_delivery_days',
      productFormData.estimated_delivery_days
    )
    data.append('note', productFormData.note)
    data.append('thumbnail', productFormData.thumbnail)

    try {
      const res = await api.createProduct(data)
      if (res.data.product) {
        productId(res.data.product.id)
      }
      setProductFormData({
        name: '',
        description: '',
        category_id: '',
        meta_description: '',
        meta_keywords: '',
        video_url: '',
        note: '',
        estimated_delivery_days: '',
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message)
      } else {
        toast.error(error?.message)
      }
    }
  }

  // update a Product
  const updateProduct = async (event) => {
    setLoading(true)
    event.preventDefault()
    const data = new FormData()
    data.append('name', productFormData.name)
    data.append('description', productFormData.description)
    data.append('category_id', productFormData.category_id)
    data.append('meta_description', productFormData.meta_description)
    data.append('meta_keywords', productFormData.meta_keywords)
    data.append('video_url', productFormData.video_url)
    data.append('note', productFormData.note)
    data.append(
      'estimated_delivery_days',
      productFormData.estimated_delivery_days
    )
    data.append('thumbnail', productFormData.thumbnail)
    try {
      const res = await api.updateProduct(productId, data)
      if (res.data.product) {
        productId(res.data.product.id)
      }
      setProductFormData({
        name: '',
        description: '',
        category_id: '',
        meta_description: '',
        meta_keywords: '',
        video_url: '',
        note: '',
        estimated_delivery_days: '',
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message)
      } else {
        toast.error(error?.message)
      }
    }
  }

  return (
    <>
      {loading && <Loading />}
      {isOpen && (
        <CreateCategory
          whatDoYouWant={'create'}
          handleClose={() => setIsOpen(false)}
          refreshState={() => setRefresh(!refresh)}
          isOpen={() => setIsOpen(!isOpen)}
        />
      )}
      <div className='flex justify-between items-center gap-5 pb-1'>
        <h1 className='font-medium'>Create Product</h1>
        <Button variant='text' onClick={() => setIsOpen(true)}>
          Create Category
        </Button>
      </div>
      <form action='#' className='flex flex-col gap-5 w-full'>
        <div>
          <TextField
            id='name'
            label='Name'
            value={productFormData.name}
            variant='outlined'
            fullWidth={true}
            size='small'
            color='info'
            required
            name='name'
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            id='thumbnail'
            name='thumbnail'
            type='file'
            disableUnderline={true}
            fullWidth={true}
            className='w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1'
            onChange={handleChange}
          ></Input>
        </div>
        <div>
          <div className='grid gap-3 grid-cols-1 sm:grid-cols-2'>
            <div>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Select Category
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  size='small'
                  name='category_id'
                  value={productFormData.category_id}
                  label='Select Category'
                  onChange={handleChange}
                >
                  {allCategories?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                id='video_url'
                label='Video Url'
                value={productFormData.video_url}
                variant='outlined'
                fullWidth={true}
                size='small'
                color='info'
                name='video_url'
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='grid gap-3 grid-cols-1 sm:grid-cols-2'>
          <div>
            <TextField
              id='meta_keywords'
              label='Meta Keywords'
              value={productFormData.meta_keywords}
              variant='outlined'
              fullWidth={true}
              size='small'
              color='info'
              required
              name='meta_keywords'
              onChange={handleChange}
            />
          </div>

          <div>
            <TextField
              id='estimated_delivery_days'
              label='Estimated Delivery Days'
              value={productFormData.estimated_delivery_days}
              variant='outlined'
              type='number'
              fullWidth
              size='small'
              color='info'
              required
              name='estimated_delivery_days'
              onChange={handleChange}
              inputProps={{ min: 0 }} // Optional: Prevent negative numbers
            />
          </div>
        </div>
        <div>
          <TextField
            id='note'
            label='Note'
            value={productFormData.note}
            variant='outlined'
            fullWidth={true}
            size='small'
            color='info'
            name='note'
            onChange={handleChange}
          />
        </div>
        <div>
          <TextField
            id='meta_description'
            label='Meta Description'
            value={productFormData.meta_description}
            variant='outlined'
            fullWidth={true}
            multiline
            size='small'
            color='info'
            required
            name='meta_description'
            onChange={handleChange}
          />
        </div>

        <div>
          <Description
            data={productFormData.description}
            changeData={(data) =>
              setProductFormData({ ...productFormData, description: data })
            }
          />
        </div>
        <div>
          <Button
            disabled={
              !productFormData.name.length > 0 ||
              !productFormData.category_id > 0 ||
              !productFormData.description.length > 0 ||
              !productFormData.meta_description.length > 0 ||
              !productFormData.meta_keywords.length > 0
              // !productFormData.estimated_delivery_days.length > 0
              //   TODO: Add validation for Image
            }
            variant='contained'
            disableElevation
            onClick={(event) => {
              if (WhatDoYouWant === 'edit') {
                updateProduct(event)
              } else {
                createProduct(event)
              }
              if (handleClose) {
                handleClose()
              }
            }}
          >
            {WhatDoYouWant === 'edit' ? 'Save' : 'Submit'}
          </Button>
        </div>
      </form>
    </>
  )
}

export default ProductForm
