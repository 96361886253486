import React, { useEffect, useState } from 'react'
import * as api from '../../utils/api/apiClient'
import { Button, Checkbox, Chip, FormControlLabel, Stack } from '@mui/material'
import { toast } from 'react-toastify'
import Loading from '../Loading'
import CreateTag from '../../components/ReUseFunctionComponent/CreateTag'
import { IoMdClose, IoMdSearch } from 'react-icons/io'

function Tags({ productId }) {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [allTags, setAllTags] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [refresh, setRefresh] = useState(false)

  const addTag = async (tagId) => {
    setLoading(true)
    try {
      const data = {
        product_id: +productId,
        tag_id: tagId.id,
      }
      const res = await api.addTagToProduct(data)
      if (res) {
        setRefresh(!refresh)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const removeTag = async (tag) => {
    setLoading(true)
    try {
      const res = await api.deleteTagFromVariant(tag.product_tag_id)
      if (res) {
        setRefresh(!refresh)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  useEffect(() => {
    const productTags = async () => {
      setLoading(true)
      try {
        const res = await api.getTagsToProduct(productId)
        setSelectedTags(res.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    productTags()
  }, [productId, refresh])

  useEffect(() => {
    const getTags = async () => {
      setLoading(true)
      const data = []
      try {
        const res = await api.getAllTags()
        res.data?.forEach((element) => {
          let isIncluded = false
          selectedTags?.forEach((ele) => {
            if (element.id === ele.tag_id) {
              isIncluded = true
            }
          })
          if (!isIncluded) {
            data.push(element)
          }
        })
        setAllTags(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    getTags()
  }, [refresh, selectedTags])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const clearSearch = () => {
    setSearchQuery('')
  }

  const filteredTags = allTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <>
      {loading && <Loading />}
      {isOpen && (
        <CreateTag
          refreshState={() => setRefresh(!refresh)}
          isOpen={isOpen}
          handleClose={handleClose}
          whatDoYouWant='create'
        />
      )}
      <div className='flex flex-col gap-3'>
        {allTags.length > 0 && (
          <div>
            <div className='flex justify-between items-center gap-5 pb-1'>
              <h1 className='font-medium'>All Tags</h1>
              <div className='relative w-64'>
                <div className='relative flex items-center'>
                  <div className='absolute left-3 text-gray-400'>
                    <IoMdSearch size={20} />
                  </div>
                  <input
                    type='text'
                    placeholder='Search tags...'
                    value={searchQuery}
                    onChange={handleSearch}
                    className='w-full py-2 pl-10 pr-8 text-sm bg-white border border-gray-300 rounded-lg 
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500
                      transition-all duration-200 ease-in-out
                      placeholder-gray-400'
                  />
                  {searchQuery && (
                    <button
                      type='button'
                      onClick={clearSearch}
                      className='absolute right-2 p-1 text-gray-400 hover:text-gray-600 
                        rounded-full hover:bg-gray-100 transition-colors duration-200'
                    >
                      <IoMdClose size={18} />
                    </button>
                  )}
                </div>
                {searchQuery && filteredTags.length === 0 && (
                  <div
                    className='absolute w-full mt-1 p-2 text-sm text-gray-500 bg-white border 
                    border-gray-200 rounded-md shadow-sm'
                  >
                    No tags found
                  </div>
                )}
                {searchQuery && filteredTags.length > 0 && (
                  <div
                    className='absolute w-full mt-1 py-1 bg-white border border-gray-200 
                    rounded-md shadow-sm max-h-48 overflow-y-auto z-10'
                  >
                    <div className='text-xs text-gray-500 px-2 mb-1'>
                      {filteredTags.length} tag
                      {filteredTags.length !== 1 ? 's' : ''} found
                    </div>
                    {filteredTags.slice(0, 5).map((tag) => (
                      <div
                        key={tag.id}
                        className='px-2 py-1 hover:bg-gray-50 cursor-pointer text-sm'
                        onClick={() => {
                          if (
                            !selectedTags.some((st) => st.tag_id === tag.id)
                          ) {
                            addTag(tag)
                            setSelectedTags([...selectedTags, tag])
                          }
                          setSearchQuery('')
                        }}
                      >
                        {tag.name}
                      </div>
                    ))}
                    {filteredTags.length > 5 && (
                      <div className='text-xs text-gray-500 px-2 pt-1 border-t'>
                        {filteredTags.length - 5} more tags...
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Button variant='text' onClick={() => setIsOpen(true)}>
                Create Tag
              </Button>
            </div>
            <div
              className={`border border-mediumgray border-opacity-50 rounded-md outline-none cursor-pointer p-1 ${
                productId === undefined ? '' : 'hover:border-black'
              }`}
            >
              {filteredTags.map((tag, index) => (
                <FormControlLabel
                  key={index}
                  disabled={productId === undefined ? true : false}
                  control={
                    <Checkbox
                      checked={selectedTags.some(
                        (selectedTag) => selectedTag.tag_id === tag.id
                      )}
                      onChange={(e) => {
                        addTag(tag)
                        setSelectedTags([...selectedTags, tag])
                      }}
                    />
                  }
                  label={tag.name}
                />
              ))}
            </div>
          </div>
        )}
        {selectedTags.length > 0 && (
          <div className='border border-mediumgray border-opacity-50 rounded-md p-1'>
            <h1 className='font-medium pb-1'>Selected Tags</h1>
            <Stack direction='row' spacing={1} className='flex flex-wrap gap-2'>
              {selectedTags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag.tag_name}
                  onDelete={() => removeTag(tag)}
                  variant='outlined'
                />
              ))}
            </Stack>
          </div>
        )}
      </div>
    </>
  )
}

export default Tags
